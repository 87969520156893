import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Mulai from "../views/Mulai.vue";
import Cetak from "../views/Cetak.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/mulai",
    name: "mulai",
    component: Mulai,
  },
  {
    path: "/cetak",
    name: "cetak",
    component: Cetak,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
