<template>
  <div>
    <v-row v-show="tampil == 'hasil'">
      <v-col cols="12">
        <TabelCetak
          @cetakpendaftaran="cetakPendaftaran"
          @resetpendaftaran="resetPendaftaran"
        />
      </v-col>
    </v-row>
    <DialogCetak ref="ref_dialog_cetak" />
    <v-row v-show="tampil == 'cetak'">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <object
              width="100%"
              height="800"
              :data="urlHasil"
              type="application/pdf"
            >
              <iframe :src="urlHasil"></iframe>
            </object>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TabelCetak from "@/components/TabelCetak";
export default {
  components: {
    TabelCetak,
  },
  data() {
    return {
      urlHasil: "",
      tampil: "hasil",
    };
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem("HASIL")) {
        let hasil = JSON.parse(localStorage.getItem("HASIL"));
        this.urlHasil = process.env.VUE_APP_CETAK_URL + "?id=" + hasil.id;
        this.$forceUpdate();
      }
    });
  },
  methods: {
    cetakPendaftaran() {
      window.open(this.urlHasil);
    },
    resetPendaftaran() {
      let self = this;
      this.$swal({
        icon: "info",
        title: "Pemberitahuan",
        html: "Pastikan Anda sudah mendownload bukti pendaftaran... <br> Klik Ya untuk menginputkan pendaftaran lagi ",
        customClass: "swal-aplikasi",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          localStorage.clear();
          self.$router.push("/");
        }
      });
    },
  },
};
</script>
