<template>
  <v-container
    style="
      padding: 12px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div class="cover-mulai">
      <v-card
        outlined
        style="
          box-shadow: rgb(0 0 0 / 70%) 3px 6px 10px -9px;
          -webkit-box-shadow: rgb(0 0 0 / 70%) 3px 6px 10px -9px;
          -moz-box-shadow: rgb(0 0 0 / 70%) 3px 6px 10px -9px;
          background-color: #3fb679;
          color: white;
        "
      >
        <v-card-text>
          <h3 style="color: white">
            Selamat Datang Calon Peserta Didik Baru di PPDB Online MTs
            Asy-Syukuriyah Payaman Bojonegoro, Tahun Ajaran 2024/2025
          </h3>
        </v-card-text>
      </v-card>
      <br />
      <v-stepper v-model="stepke" vertical color="green">
        <v-stepper-step :complete="stepke > 1" step="1">
          Identitas Siswa
          <small>Isilah formulir pendaftaran berikut dengan data lengkap</small>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="first_namaLengkap"
                    :rules="[(v) => !!v || 'Nama harus diisi']"
                    placeholder="misal: Muhammad Al-Kautsar"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Nama Lengkap <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-text-field>
                  <v-select
                    v-model="first_jenisKelamin"
                    :items="arrJenisKelamin"
                    :rules="[(v) => !!v || 'Jenis Kelamin harus dipiih']"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Jenis Kelamin <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="first_tempatLahir"
                    :rules="[(v) => !!v || 'Tempat Lahir harus diisi']"
                    placeholder="misal: Bojonegoro"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Tempat Lahir <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-text-field>
                  <v-dialog
                    ref="dialog"
                    v-model="modalLahir"
                    :return-value.sync="first_dateLahir"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="first_dateLahir"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      >
                        <template v-slot:label>
                          <label>
                            Tanggal Lahir <sup style="color: red">*</sup>
                          </label>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="first_dateLahir"
                      scrollable
                      :allowed-dates="disableFutureDates"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalLahir = false">
                        Batal
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(first_dateLahir)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field
                    v-model="first_nisn"
                    :rules="[(v) => !!v || 'NISN harus diisi']"
                    placeholder="misal: 0709798878"
                    filled
                    @keypress="isNumber($event)"
                  >
                    <template v-slot:label>
                      <label>
                        NISN (Nomor Induk Siswa Nasional)
                        <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-text-field>
                  <div style="margin-top: -10px">
                    <small>
                      Bisa lihat di buku raport atau cek di link :
                      <a
                        target="_blank"
                        href="https://nisn.data.kemdikbud.go.id/index.php/Cindex/formcaribynama"
                        >https://nisn.data.kemdikbud.go.id/index.php/Cindex/formcaribynama</a
                      >
                    </small>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-select
                    v-model="first_agama"
                    :items="arrAgama"
                    :rules="[(v) => !!v || 'Agama harus dipiih']"
                    filled
                  >
                    <template v-slot:label>
                      <label> Agama <sup style="color: red">*</sup> </label>
                    </template>
                  </v-select>
                  <v-select
                    v-model="first_anakKe"
                    :items="listAngka"
                    filled
                    label="Anak ke"
                  >
                  </v-select>
                  <v-select
                    v-model="first_jumlahSaudara"
                    :items="listAngka"
                    filled
                    label="Jumlah Saudara"
                  />
                  <v-select
                    v-model="first_statusKeluarga"
                    :items="arrStatusKeluarga"
                    :rules="[
                      (v) => !!v || 'Status dalam keluarga harus dipiih',
                    ]"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Status dalam keluarga <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-select>
                  <v-textarea
                    v-model="first_alamat"
                    filled
                    :rules="[(v) => !!v || 'Alamat harus diisi']"
                    placeholder="Jalan Ario Jipang, RT 001, RW 001, Dusun Ketawang, Kelurahan Payaman, Kecamatan Ngraho, Kabupaten Bojonegoro"
                  >
                    <template v-slot:label>
                      <label> Alamat <sup style="color: red">*</sup> </label>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn
            class="btn-step-selanjutnya text-none"
            dark
            @click="goToStep(2, 'next')"
          >
            <span>Selanjutnya</span>
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="stepke > 2" step="2">
          Riwayat Sekolah (SD) / Madrasah (MI)
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="second_namaSekolah"
                    :rules="[(v) => !!v || 'Nama Sekolah harus diisi']"
                    placeholder="misal: MI Matholiul Falah"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Nama Sekolah SD/MI <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-text-field>
                  <v-textarea
                    v-model="second_alamatSekolah"
                    filled
                    placeholder="Tuliskan Alamat Sekolah . . ."
                    label="Alamat Sekolah"
                  >
                  </v-textarea>
                  <v-text-field
                    v-model="second_prestasi"
                    placeholder="misal: Juara 1 lomba bahasa arab"
                    label="Prestasi yang pernah dicapai"
                    filled
                  />
                  <v-text-field
                    v-model="second_beasiswa"
                    placeholder="misal: Beasiswa Prestasi"
                    label="Bantuan / Beasiswa yang pernah diterima"
                    filled
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn text class="text-none" outlined @click="stepke = 1">
            <v-icon small>mdi-chevron-left</v-icon> <span>Kembali</span>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn-step-selanjutnya text-none"
            dark
            @click="goToStep(3, 'next')"
          >
            <span>Selanjutnya</span>
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="stepke > 3" step="3">
          Identitas Orang Tua
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="third_namaAyah"
                    :rules="[(v) => !!v || 'Nama Ayah harus diisi']"
                    placeholder="misal: Muhammad Ibrahim"
                    filled
                  >
                    <template v-slot:label>
                      <label> Nama Ayah <sup style="color: red">*</sup> </label>
                    </template>
                  </v-text-field>
                  <v-select
                    v-model="third_agamaAyah"
                    :items="arrAgama"
                    :rules="[(v) => !!v || 'Agama Ayah harus dipiih']"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Agama Ayah <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="third_hpAyah"
                    placeholder="misal: 0821xxxxxxxx"
                    filled
                    @keypress="isNumber($event)"
                  >
                    <template v-slot:label>
                      <label> HP Ayah </label>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="third_tempatLahirAyah"
                    placeholder="misal: Bojonegoro"
                    label="Tempat Lahir Ayah"
                    filled
                  />
                  <v-text-field
                    v-model="third_pekerjaanAyah"
                    placeholder="misal: Pedagang"
                    label="Pekerjaan Ayah"
                    filled
                  />
                  <v-textarea
                    v-model="third_alamatAyah"
                    filled
                    placeholder="Jalan Ario Jipang, RT 001, RW 001, Dusun Ketawang, Kelurahan Payaman, Kecamatan Ngraho, Kabupaten Bojonegoro"
                    label="Alamat Ayah"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="third_namaIbu"
                    :rules="[(v) => !!v || 'Nama Ibu harus diisi']"
                    placeholder="misal: Siti Hajar"
                    filled
                  >
                    <template v-slot:label>
                      <label> Nama Ibu <sup style="color: red">*</sup> </label>
                    </template>
                  </v-text-field>
                  <v-select
                    v-model="third_agamaIbu"
                    :items="arrAgama"
                    :rules="[(v) => !!v || 'Agama Ibu harus dipiih']"
                    filled
                  >
                    <template v-slot:label>
                      <label> Agama Ibu <sup style="color: red">*</sup> </label>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="third_hpIbu"
                    placeholder="misal: 0821xxxxxxxx"
                    @keypress="isNumber($event)"
                    filled
                  >
                    <template v-slot:label>
                      <label> HP Ibu </label>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="third_tempatLahirIbu"
                    placeholder="misal: Bojonegoro"
                    label="Tempat Lahir Ibu"
                    filled
                  />
                  <v-text-field
                    v-model="third_pekerjaanIbu"
                    placeholder="misal: Pedagang"
                    label="Pekerjaan Ibu"
                    filled
                  />
                  <v-textarea
                    v-model="third_alamatIbu"
                    filled
                    placeholder="Jalan Ario Jipang, RT 001, RW 001, Dusun Ketawang, Kelurahan Payaman, Kecamatan Ngraho, Kabupaten Bojonegoro"
                    label="Alamat Ibu"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn text class="text-none" outlined @click="stepke = 2">
            <v-icon small>mdi-chevron-left</v-icon> <span>Kembali</span>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn-step-selanjutnya text-none"
            dark
            @click="goToStep(4, 'next')"
          >
            <span>Selanjutnya</span>
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="4"> Identitas Wali </v-stepper-step>
        <v-stepper-content step="4">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="fourth_namaWali"
                    :rules="[(v) => !!v || 'Nama wali diisi']"
                    placeholder="misal: Muhammad Ibrahim"
                    filled
                  >
                    <template v-slot:label>
                      <label> Nama Wali <sup style="color: red">*</sup> </label>
                    </template>
                  </v-text-field>
                  <v-select
                    v-model="fourth_agamaWali"
                    :items="arrAgama"
                    :rules="[(v) => !!v || 'Agama Wali harus dipiih']"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Agama Wali <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="fourth_hpWali"
                    :rules="[
                      (v) => !!v || 'Nomor Telepon / HP / WA Wali harus diisi',
                    ]"
                    placeholder="misal: 0821xxxxxxxx"
                    @keypress="isNumber($event)"
                    filled
                  >
                    <template v-slot:label>
                      <label>
                        Nomor Telepon / HP / WA Wali
                        <sup style="color: red">*</sup>
                      </label>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="fourth_tempatLahirWali"
                    placeholder="misal: Bojonegoro"
                    label="Tempat Lahir Wali"
                    filled
                  />
                  <v-text-field
                    v-model="fourth_pekerjaanWali"
                    placeholder="misal: Pedagang"
                    label="Pekerjaan Wali"
                    filled
                  />
                  <v-textarea
                    v-model="fourth_alamatWali"
                    filled
                    placeholder="Jalan Ario Jipang, RT 001, RW 001, Dusun Ketawang, Kelurahan Payaman, Kecamatan Ngraho, Kabupaten Bojonegoro"
                    label="Alamat Wali"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn text class="text-none" outlined @click="stepke = 3">
            <v-icon small>mdi-chevron-left</v-icon> <span>Kembali</span>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn-step-selanjutnya text-none"
            dark
            @click="submitPendaftaran"
            :loading="loading"
            :disabled="loading"
          >
            <span>Simpan</span>
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-container>
</template>
<script>
const moment = require("moment");
moment.locale("id");
import validasi from "../../mixins/validasi";
export default {
  mixins: [validasi],
  data() {
    return {
      stepke: 1,
      loading: false,
      first_nisn: "",
      first_namaLengkap: "",
      first_jenisKelamin: "",
      first_tempatLahir: "",
      first_dateLahir: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      first_alamat: "",
      first_agama: "",
      first_anakKe: "",
      first_jumlahSaudara: "",
      first_statusKeluarga: "",

      second_namaSekolah: "",
      second_alamatSekolah: "",
      second_prestasi: "",
      second_beasiswa: "",

      third_namaAyah: "",
      third_agamaAyah: "",
      third_hpAyah: "",
      third_tempatLahirAyah: "",
      third_pekerjaanAyah: "",
      third_alamatAyah: "",
      third_namaIbu: "",
      third_agamaIbu: "",
      third_hpIbu: "",
      third_tempatLahirIbu: "",
      third_pekerjaanIbu: "",
      third_alamatIbu: "",

      fourth_namaWali: "",
      fourth_agamaWali: "",
      fourth_hpWali: "",
      fourth_tempatLahirWali: "",
      fourth_pekerjaanWali: "",
      fourth_alamatWali: "",

      arrJenisKelamin: [
        {
          value: "L",
          text: "Laki-Laki",
        },
        {
          value: "P",
          text: "Perempuan",
        },
      ],
      arrStatusKeluarga: [
        {
          value: "AK",
          text: "Anak Kandung",
        },
        {
          value: "AA",
          text: "Anak Angkat",
        },
        {
          value: "AT",
          text: "Anak Tiri",
        },
      ],
      arrAgama: ["Islam", "Kristen", "Katolik", "Hindu", "Budha", "Konghucu"],

      modalLahir: false,
      urlHasil: '',
    };
  },
  computed: {
    listAngka: function () {
      let angkanya = [];
      for (let index = 1; index <= 20; index++) {
        angkanya.push(index);
      }
      return angkanya;
    },
  },
  mounted() {
    if (localStorage.getItem("STEP1")) {
      let step1 = JSON.parse(localStorage.getItem("STEP1"));

      this.first_namaLengkap = step1.first_namaLengkap;
      this.first_jenisKelamin = step1.first_jenisKelamin;
      this.first_tempatLahir = step1.first_tempatLahir;
      this.first_dateLahir = step1.first_dateLahir;
      this.first_alamat = step1.first_alamat;
      this.first_agama = step1.first_agama;
      this.first_anakKe = step1.first_anakKe;
      this.first_jumlahSaudara = step1.first_jumlahSaudara;
      this.first_statusKeluarga = step1.first_statusKeluarga;
      this.first_nisn = step1.first_nisn;
    }

    if (localStorage.getItem("STEP2")) {
      let step2 = JSON.parse(localStorage.getItem("STEP2"));

      this.second_namaSekolah = step2.second_namaSekolah;
      this.second_alamatSekolah = step2.second_alamatSekolah;
      this.second_prestasi = step2.second_prestasi;
      this.second_beasiswa = step2.second_beasiswa;
    }

    if (localStorage.getItem("STEP3")) {
      let step3 = JSON.parse(localStorage.getItem("STEP3"));

      this.third_namaAyah = step3.third_namaAyah;
      this.third_agamaAyah = step3.third_agamaAyah;
      this.third_hpAyah = step3.third_hpAyah;
      this.third_tempatLahirAyah = step3.third_tempatLahirAyah;
      this.third_pekerjaanAyah = step3.third_pekerjaanAyah;
      this.third_alamatAyah = step3.third_alamatAyah;
      this.third_namaIbu = step3.third_namaIbu;
      this.third_agamaIbu = step3.third_agamaIbu;
      this.third_hpIbu = step3.third_hpIbu;
      this.third_tempatLahirIbu = step3.third_tempatLahirIbu;
      this.third_pekerjaanIbu = step3.third_pekerjaanIbu;
      this.third_alamatIbu = step3.third_alamatIbu;
    }

    if (localStorage.getItem("STEP4")) {
      let step4 = JSON.parse(localStorage.getItem("STEP4"));
      this.fourth_namaWali = step4.fourth_namaWali;
      this.fourth_agamaWali = step4.fourth_agamaWali;
      this.fourth_hpWali = step4.fourth_hpWali;
      this.fourth_tempatLahirWali = step4.fourth_tempatLahirWali;
      this.fourth_pekerjaanWali = step4.fourth_pekerjaanWali;
      this.fourth_alamatWali = step4.fourth_alamatWali;
    }
  },
  methods: {
    cetakPendaftaran() {
      window.open(this.urlHasil);
    },
    disableFutureDates(val) {
      return val <= new Date().toISOString().substr(0, 10);
    },
    goToStep(no, typeStep) {
      if (typeStep == "next") {
        if (no == "2") {
          if (
            this.first_namaLengkap == "" ||
            this.first_jenisKelamin == "" ||
            this.first_tempatLahir == "" ||
            this.first_dateLahir == "" ||
            this.first_alamat == "" ||
            this.first_agama == "" ||
            this.first_statusKeluarga == "" ||
            this.first_nisn == ""
          ) {
            this.$swal({
              icon: "info",
              title: "Oops...",
              text: "Harap lengkapi dulu isian di step 1",
              customClass: "swal-aplikasi",
            });
          } else {
            let isiansatu = {
              first_namaLengkap: this.first_namaLengkap,
              first_jenisKelamin: this.first_jenisKelamin,
              first_tempatLahir: this.first_tempatLahir,
              first_dateLahir: this.first_dateLahir,
              first_alamat: this.first_alamat,
              first_agama: this.first_agama,
              first_anakKe: this.first_anakKe,
              first_jumlahSaudara: this.first_jumlahSaudara,
              first_statusKeluarga: this.first_statusKeluarga,
              first_nisn: this.first_nisn,
            };
            localStorage.setItem("STEP1", JSON.stringify(isiansatu));
            this.stepke = 2;
          }
        } else if (no == "3") {
          if (this.second_namaSekolah == "") {
            this.$swal({
              icon: "info",
              title: "Oops...",
              text: "Harap isi nama asal sekolah SD / MI",
              customClass: "swal-aplikasi",
            });
          } else {
            let isiandua = {
              second_namaSekolah: this.second_namaSekolah,
              second_alamatSekolah: this.second_alamatSekolah,
              second_prestasi: this.second_prestasi,
              second_beasiswa: this.second_beasiswa,
            };
            localStorage.setItem("STEP2", JSON.stringify(isiandua));
            this.stepke = 3;
          }
        } else if (no == "4") {
          if (
            this.third_namaAyah == "" ||
            this.third_agamaAyah == "" ||
            this.third_namaIbu == "" ||
            this.third_agamaIbu == ""
          ) {
            this.$swal({
              icon: "info",
              title: "Oops...",
              text: "Harap lengkapi dulu isian di step 3",
              customClass: "swal-aplikasi",
            });
          } else {
            let isiantiga = {
              third_namaAyah: this.third_namaAyah,
              third_agamaAyah: this.third_agamaAyah,
              third_hpAyah: this.third_hpAyah,
              third_tempatLahirAyah: this.third_tempatLahirAyah,
              third_pekerjaanAyah: this.third_pekerjaanAyah,
              third_alamatAyah: this.third_alamatAyah,
              third_namaIbu: this.third_namaIbu,
              third_agamaIbu: this.third_agamaIbu,
              third_hpIbu: this.third_hpIbu,
              third_tempatLahirIbu: this.third_tempatLahirIbu,
              third_pekerjaanIbu: this.third_pekerjaanIbu,
              third_alamatIbu: this.third_alamatIbu,
            };
            localStorage.setItem("STEP3", JSON.stringify(isiantiga));
            this.stepke = 4;
          }
        }
      }
    },
    submitPendaftaran() {
      if (
        this.fourth_namaWali == "" ||
        this.fourth_agamaWali == "" ||
        this.fourth_hpWali == ""
      ) {
        this.$swal({
          icon: "info",
          title: "Oops...",
          text: "Harap lengkapi dulu isian di step 4",
          customClass: "swal-aplikasi",
        });
      } else {
        let self = this;
        let isianempat = {
          fourth_namaWali: this.fourth_namaWali,
          fourth_agamaWali: this.fourth_agamaWali,
          fourth_hpWali: this.fourth_hpWali,
          fourth_tempatLahirWali: this.fourth_tempatLahirWali,
          fourth_pekerjaanWali: this.fourth_pekerjaanWali,
          fourth_alamatWali: this.fourth_alamatWali,
        };
        localStorage.setItem("STEP4", JSON.stringify(isianempat));

        this.loading = true;
        self.$http
          .post("/ppdb", {
            first_nisn: this.first_nisn,
            first_namaLengkap: this.first_namaLengkap,
            first_jenisKelamin: this.first_jenisKelamin,
            first_tempatLahir: this.first_tempatLahir,
            first_dateLahir: this.first_dateLahir,
            first_alamat: this.first_alamat,
            first_agama: this.first_agama,
            first_anakKe: this.first_anakKe,
            first_jumlahSaudara: this.first_jumlahSaudara,
            first_statusKeluarga: this.first_statusKeluarga,

            second_namaSekolah: this.second_namaSekolah,
            second_alamatSekolah: this.second_alamatSekolah,
            second_prestasi: this.second_prestasi,
            second_beasiswa: this.second_beasiswa,

            third_namaAyah: this.third_namaAyah,
            third_agamaAyah: this.third_agamaAyah,
            third_hpAyah: this.third_hpAyah,
            third_tempatLahirAyah: this.third_tempatLahirAyah,
            third_pekerjaanAyah: this.third_pekerjaanAyah,
            third_alamatAyah: this.third_alamatAyah,
            third_namaIbu: this.third_namaIbu,
            third_agamaIbu: this.third_agamaIbu,
            third_hpIbu: this.third_hpIbu,
            third_tempatLahirIbu: this.third_tempatLahirIbu,
            third_pekerjaanIbu: this.third_pekerjaanIbu,
            third_alamatIbu: this.third_alamatIbu,

            fourth_namaWali: this.fourth_namaWali,
            fourth_agamaWali: this.fourth_agamaWali,
            fourth_hpWali: this.fourth_hpWali,
            fourth_tempatLahirWali: this.fourth_tempatLahirWali,
            fourth_pekerjaanWali: this.fourth_pekerjaanWali,
            fourth_alamatWali: this.fourth_alamatWali,
          })
          .then((res) => {
            if(res.data.success == true){
              let hasil = {
                id: res.data.dataPpdb.id,
                tanggal: moment(res.data.dataPpdb.tanggal).format(
                  "dddd, DD MMMM YYYY HH:mm"
                ),
              };
              localStorage.setItem("HASIL", JSON.stringify(hasil));
              this.$router.push("/cetak");
            } else {
               let urlCetak = process.env.VUE_APP_CETAK_URL + "?id=" + res.data.dataPpdb.id
              this.$swal({
                icon: "error",
                html: `${res.data.message} 
                <br>
                <br>  
                <a href="${urlCetak}" target="_blank" class="btn-cetak-daftar-swal">
                  Cetak Bukti Daftar
                </a>
                &nbsp;
                &nbsp;
                <a href="https://s.id/Infoppdb2024-2025mtsasyukuriyah" target="_blank" class="btn-gabung-wa-swal" >
                  Gabung Grub WA
                </a>`,
                customClass: "swal-aplikasi",
              });
            }

            this.loading = false;
          })
          .catch((e) => {
            console.log("e", e);
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Data gagal disimpan !",
              customClass: "swal-aplikasi",
            });
          });
      }
    },
  },
};
</script>
<style scoped>
.cover-mulai {
  width: 95%;
  height: auto;
  padding: 15px 10px 15px 10px;
  border-radius: 18px;
  box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  background-color: white;
}
.btn-step-selanjutnya {
  background: linear-gradient(109.6deg, #38b679 9.95%, #5dda74 93.04%);
  box-shadow: 0px 7px 15px rgba(82, 194, 133, 0.59);
  border-radius: 5px;
}
</style>
<style>
.swal-aplikasi {
  font-family: sans-serif !important;
}
.swal-aplikasi .swal2-html-container{
  overflow: revert !important;
}
.btn-gabung-wa-swal {
  background: linear-gradient(109.6deg, #38b679 9.95%, #5dda74 93.04%);
  box-shadow: 0px 7px 15px rgba(82, 194, 133, 0.59);
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 10px;
  color:white
}
.btn-cetak-daftar-swal{
  background: linear-gradient(109.6deg, #384bb6 9.95%, #5d85da 93.04%);
  box-shadow: 0px 7px 15px rgba(101, 110, 240, 0.59);
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 10px;
  color:white
}
</style>
