<template>
  <div class="container-cetak">
    <v-card class="card-cetak">
      <v-card-text>
        <v-alert class="alert-sukses" dark>
          Terimakasih pendaftaran Anda telah kami terima...
          <br />
          <small
            >Silakan gabung di grup whatsapp dan download bukti pendaftaran di
            bawah !</small
          >
        </v-alert>
        <p>&rsaquo; Data Diri</p>
        <table class="tabel-cetak">
          <tr>
            <th width="20%">NISN</th>
            <td width="1%">:</td>
            <td>{{ first_nisn }}</td>
          </tr>
          <tr>
            <th>Nama Lengkap</th>
            <td width="1%">:</td>
            <td>{{ first_namaLengkap }}</td>
          </tr>
          <tr>
            <th>Jenis Kelamin</th>
            <td width="1%">:</td>
            <td>{{ first_jenisKelamin }}</td>
          </tr>
          <tr>
            <th>TTL</th>
            <td width="1%">:</td>
            <td>{{ first_tempatLahir + ", " + first_dateLahir }}</td>
          </tr>
          <tr>
            <th>Agama</th>
            <td width="1%">:</td>
            <td>{{ first_agama }}</td>
          </tr>
          <tr>
            <th>Alamat</th>
            <td width="1%">:</td>
            <td>{{ first_alamat }}</td>
          </tr>
        </table>
        <br />
        <p>&rsaquo; Data Wali</p>
        <table class="tabel-cetak">
          <tr>
            <th width="20%">Nama</th>
            <td width="1%">:</td>
            <td>{{ fourth_namaWali }}</td>
          </tr>
          <tr>
            <th>Nomor Telepon / HP / WA</th>
            <td width="1%">:</td>
            <td>{{ fourth_hpWali }}</td>
          </tr>
          <tr>
            <th>Agama</th>
            <td width="1%">:</td>
            <td>{{ fourth_agamaWali }}</td>
          </tr>
          <tr>
            <th>Pekerjaan</th>
            <td width="1%">:</td>
            <td>{{ fourth_pekerjaanWali }}</td>
          </tr>
          <tr>
            <th>Alamat</th>
            <td width="1%">:</td>
            <td>{{ fourth_alamatWali }}</td>
          </tr>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="3" sm="6">
            <a
              href="https://s.id/Infoppdb2024-2025mtsasyukuriyah"
              target="_blank"
              class="btn-gabung-wa"
            >
              <v-btn class="btn-wa text-none" dark block>
                <v-icon left>mdi-whatsapp</v-icon>
                <span>Gabung</span>
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-btn
              class="btn-cetak text-none"
              block
              dark
              @click="downloadPendaftaran"
            >
              <v-icon left>mdi-cloud-download</v-icon>
              <span>Bukti Pendaftaran</span>
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" offset-md="4" sm="6">
            <v-btn
              color="error"
              block
              outlined
              class="text-none"
              @click="resetPendaftaran"
            >
              <v-icon left>mdi-refresh</v-icon>
              <span>Daftar Lagi</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
const moment = require("moment");
moment.locale("id");
export default {
  data() {
    return {
      first_nisn: "",
      first_namaLengkap: "",
      first_jenisKelamin: "",
      first_tempatLahir: "",
      first_dateLahir: "",
      first_alamat: "",
      first_agama: "",
      first_anakKe: "",
      first_jumlahSaudara: "",
      first_statusKeluarga: "",

      second_namaSekolah: "",
      second_alamatSekolah: "",
      second_prestasi: "",
      second_beasiswa: "",

      third_namaAyah: "",
      third_agamaAyah: "",
      third_hpAyah: "",
      third_tempatLahirAyah: "",
      third_pekerjaanAyah: "",
      third_alamatAyah: "",
      third_namaIbu: "",
      third_agamaIbu: "",
      third_hpIbu: "",
      third_tempatLahirIbu: "",
      third_pekerjaanIbu: "",
      third_alamatIbu: "",

      fourth_namaWali: "",
      fourth_agamaWali: "",
      fourth_hpWali: "",
      fourth_tempatLahirWali: "",
      fourth_pekerjaanWali: "",
      fourth_alamatWali: "",
    };
  },
  mounted() {
    if (localStorage.getItem("STEP1")) {
      let step1 = JSON.parse(localStorage.getItem("STEP1"));

      this.first_namaLengkap = step1.first_namaLengkap;
      this.first_jenisKelamin = step1.first_jenisKelamin;
      this.first_tempatLahir = step1.first_tempatLahir;
      this.first_dateLahir = moment(step1.first_dateLahir).format(
        "DD MMMM YYYY"
      );
      this.first_alamat = step1.first_alamat;
      this.first_agama = step1.first_agama;
      this.first_anakKe = step1.first_anakKe;
      this.first_jumlahSaudara = step1.first_jumlahSaudara;
      this.first_statusKeluarga = step1.first_statusKeluarga;
      this.first_nisn = step1.first_nisn;
    }

    if (localStorage.getItem("STEP2")) {
      let step2 = JSON.parse(localStorage.getItem("STEP2"));

      this.second_namaSekolah = step2.second_namaSekolah;
      this.second_alamatSekolah = step2.second_alamatSekolah;
      this.second_prestasi = step2.second_prestasi;
      this.second_beasiswa = step2.second_beasiswa;
    }

    if (localStorage.getItem("STEP3")) {
      let step3 = JSON.parse(localStorage.getItem("STEP3"));

      this.third_namaAyah = step3.third_namaAyah;
      this.third_agamaAyah = step3.third_agamaAyah;
      this.third_hpAyah = step3.third_hpAyah;
      this.third_tempatLahirAyah = step3.third_tempatLahirAyah;
      this.third_pekerjaanAyah = step3.third_pekerjaanAyah;
      this.third_alamatAyah = step3.third_alamatAyah;
      this.third_namaIbu = step3.third_namaIbu;
      this.third_agamaIbu = step3.third_agamaIbu;
      this.third_hpIbu = step3.third_hpIbu;
      this.third_tempatLahirIbu = step3.third_tempatLahirIbu;
      this.third_pekerjaanIbu = step3.third_pekerjaanIbu;
      this.third_alamatIbu = step3.third_alamatIbu;
    }

    if (localStorage.getItem("STEP4")) {
      let step4 = JSON.parse(localStorage.getItem("STEP4"));
      this.fourth_namaWali = step4.fourth_namaWali;
      this.fourth_agamaWali = step4.fourth_agamaWali;
      this.fourth_hpWali = step4.fourth_hpWali;
      this.fourth_tempatLahirWali = step4.fourth_tempatLahirWali;
      this.fourth_pekerjaanWali = step4.fourth_pekerjaanWali;
      this.fourth_alamatWali = step4.fourth_alamatWali;
    }
  },
  methods: {
    downloadPendaftaran() {
      this.$emit("cetakpendaftaran");
    },
    resetPendaftaran() {
      this.$emit("resetpendaftaran");
    },
  },
};
</script>
<style scoped>
.tabel-cetak {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.tabel-cetak th,
.tabel-cetak td {
  text-align: left;
  padding: 8px;
}

.tabel-cetak tr:nth-child(even) {
  background-color: #f2f2f2;
}
.card-cetak {
  height: auto;
  width: 95%;
  padding: 10px;
  margin: 20px;
  border-radius: 18px;
  box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
}
.container-cetak {
  display: flex;
  align-items: center !important;
  padding: 12px 0px;
  justify-content: center !important;
}
.alert-sukses {
  background-color: #38b679 !important;
}
.btn-cetak {
  background: linear-gradient(109.6deg, #003bcd 9.95%, #0b5991 93.04%);
  box-shadow: -1px 4px 13px rgb(20 39 195 / 59%);
  border-radius: 5px;
}
.btn-wa {
  background: linear-gradient(109.6deg, #38b679 9.95%, #5dda74 93.04%);
  box-shadow: 0px 7px 15px rgba(82, 194, 133, 0.59);
  border-radius: 5px;
}
.btn-gabung-wa {
  text-decoration: none;
}
</style>
