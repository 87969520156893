<template>
  <v-container
    style="
      height: 100vh;
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <div class="cover-mulai">
      <center>
        <v-img
          width="30%"
          src="@/assets/img/logo-mts-asy-syukuriyah.png"
          alt=""
        />
        <br />
        <h1>PPDB Online</h1>
        <p>
          Selamat datang calon peserta didik baru di Pendaftaran Online MTs
          Asy-Syukuriyah Payaman - Bojonegoro
        </p>
        <p>
          Untuk mempermudah penginputan silahkan siapkan KK , NISN (Bisa di cek
          di raport atau di
          <a
            href="https://nisn.data.kemdikbud.go.id/index.php/Cindex/formcaribynama"
            >https://nisn.data.kemdikbud.go.id/index.php/Cindex/formcaribynama</a
          >), dan no hp yang bisa dihubungi.
        </p>
        <br />
        <v-btn dark class="btn-mulai" to="/mulai">Mulai &raquo;</v-btn>
        <br />
        <br />
      </center>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style scoped>
.cover-mulai {
  width: 88%;
  height: auto;
  padding: 65px 15px 30px 15px;
  border-radius: 18px;
  box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 3px 6px 26px -14px rgba(0, 0, 0, 0.7);
  background-color: white;
}
.btn-mulai {
  background: linear-gradient(109.6deg, #38b679 9.95%, #5dda74 93.04%);
  box-shadow: 0px 7px 15px rgba(82, 194, 133, 0.59);
  border-radius: 5px;
}
</style>
